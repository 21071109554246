<template>
    <section class='row section-perks-and-benefits'>
        <!-- Content -->
        <div class="offset-xl-1 col-xs-12 col-xl-3 content">
          <h2 class="title">{{ $t('perksAndBenefits.headerTitle') }}</h2>
          <p class="paragraph">{{ $t('perksAndBenefits.headerDescription1') }} <br class="d-none d-inline-lg" />{{ $t('perksAndBenefits.headerDescription2') }}</p>
        </div>

        <!-- Details of Perks and Benefits -->
        <div class="col-12 col-xl-7 text-left details">
          <div class="row">
            <div class="offset-1 offset-xl-1 offset-md-1 col-10 col-md-5">
              <div class="details-card">
                <div class="media">
                  <img src="../../assets/img/icons-careers-section-perks-and-benefits/1.svg" class="image">
                  <div class="media-body">
                    <h2 class="title title-1">{{ $t('perksAndBenefits.title1') }}</h2>
                    <p class="paragraph">{{ $t('perksAndBenefits.paragraph1') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="offset-1 offset-md-0 offset-xl-1 col-10 col-md-5">
              <div class="details-card">
                <div class="media">
                  <img src="../../assets/img/icons-careers-section-perks-and-benefits/2.svg" class="image mr30">
                  <div class="media-body">
                    <h2 class="title title-2">{{ $t('perksAndBenefits.title2') }}</h2>
                    <p class="paragraph">{{ $t('perksAndBenefits.paragraph2') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="offset-1 offset-xl-1 offset-md-1 col-10 col-md-5">
              <div class="details-card">
                <div class="media">
                  <img src="../../assets/img/icons-careers-section-perks-and-benefits/3.svg" class="image mr30">
                  <div class="media-body">
                    <h2 class="title title-3">{{ $t('perksAndBenefits.title3') }}</h2>
                    <p class="paragraph">{{ $t('perksAndBenefits.paragraph3') }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="offset-1 offset-md-0 offset-xl-1 col-10 col-md-5">
              <div class="details-card">
                <div class="media">
                  <img src="../../assets/img/icons-careers-section-perks-and-benefits/4.svg" class="image mr30">
                  <div class="media-body">
                    <h2 class="title title-4">{{ $t('perksAndBenefits.title4') }}</h2>
                    <p class="paragraph">{{ $t('perksAndBenefits.paragraph4') }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: "SectionPerksAndBenefits",
  components: {
    
  },
};
</script>

<style lang="scss">
@import '../../assets/css/scss/careers/section-perks-and-benefits.scss';
</style>

