<template>
    <section class='row section-page-header'>
        <div class="overlay-bg-careers-tablet"></div>

        <!-- Navbar: Not the right way to do it, will require refactoring -->
        <MainNav />

        <!-- Content -->
        <div class="offset-md-1 col-12 col-md-10 col-lg-4 content">
          <h2 class="title">{{ $t('careersPageHeader.title') }}</h2>
          <p class="paragraph">{{ $t('careersPageHeader.paragraph1') }}</p>
          <p class="paragraph">{{ $t('careersPageHeader.paragraph2') }}</p>
        </div>
    </section>
</template>

<script>
// @ is an alias to /src
import MainNav from '@/components/MainNav.vue';

export default {
  name: "SectionPageHeader",
  components: {
    MainNav,
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/css/scss/careers/section-page-header.scss';
</style>

