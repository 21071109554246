<template>
  <div class="form-opening-positions">
    <div class="offset-lg-1 offset-xl-2 col-lg-10 col-xl-8">
      <!-- Form Header -->
      <div class="form-header text-center">
        <h2 class="title">{{ $t('openPositions.title') }}</h2>
        <p class="paragraph">{{ $t('openPositions.paragraph') }}</p>
      </div>

      <!-- <pre>{{ locale }}</pre> -->

      <!-- Search Criteria -->
      <form class="form-inline form-search-criteria">
        <div class="custom-select-style">
          <select id="select-department" class="custom-select">
            <option>Choose a department</option>
            <option v-for="dep in departments" :value="dep.id" :key="dep.id">{{ dep.enName }}</option>
          </select>
        </div>

        <div class="custom-select-style">
          <select id="select-location" class="custom-select">
            <option>Choose location</option>
            <option v-for="loc in locations" :value="loc.id" :key="loc.id">{{ loc.enName }}</option>
          </select>
        </div>

        <button type="button" class="btn btn-search" @click.prevent="updatePostings">{{ $t('openPositions.searchBtn') }}</button>
      </form>

      <!-- Accordian -->
      <Accordian ref="careerPostings" :departments="departments" :locations="locations"/>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import "select2";
import Accordian from './OpenPositionAccordian.vue';
import i18n from '@/i18n';

export default {
  name: "FormOpeningPositions",
  components: { Accordian },
  data: function() {
    return {
      departments: [
        {
          id: 1,
          'enName': 'All',
        },
      ],
      locations: [
        {
          id: 1,
          'enName': 'Singapore',
        }
      ],
    };
  },
  created() {
    // console.log(i18n);
    // this.getLocation();
    // this.getDepartments();

    $(document).ready(() => {
      $("select").select2({
        minimumResultsForSearch: -1
      });
    });
  },
  methods: {
    updatePostings() {
      let department = $('#select-department').val();
      let location = $('#select-location').val();
      this.$refs.careerPostings.getPostings(department, location);
    },
    getLocation() {
      axios.get("http://testdeployd.aezypay.com/location").then(res => (this.locations = res.data));
    },
    getDepartments() {
      axios.get("http://testdeployd.aezypay.com/departments").then(res => (this.departments = res.data));
    }
  }
};
</script>

<style lang="scss">
@import "../../../node_modules/select2/dist/css/select2.css";
@import "../../../node_modules/select2-theme-bootstrap4/dist/select2-bootstrap.css";
@import "../../assets/css/scss/careers/form-opening-positions.scss";
</style>

