<template>
  <div class="careers page">
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <SectionPageHeader/>
    <SectionPerksAndBenefits/>
    <FormOpeningPositions/>
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import SectionPageHeader from "@/components/careers/SectionPageHeader.vue";
import SectionPerksAndBenefits from "@/components/careers/SectionPerksAndBenefits.vue";
import FormOpeningPositions from "@/components/careers/FormOpeningPositions.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "career",
  head: {
    title: { inner: "Career" }
  },
  components: {
    SectionPageHeader,
    SectionPerksAndBenefits,
    FormOpeningPositions,
    Footer
  },
};
</script>

<style lang="sass">
</style>

